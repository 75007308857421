import React from "react";

import "line-awesome/dist/line-awesome/css/line-awesome.min.css";

import "../../styles/pages/civicHacker.scss";

import Header from "../../components/Header";
import SEO from "../../components/seo";
import PostListVerbose from "../../components/PostListVerbose";
import { graphql } from "gatsby";
import { ISEOProps } from "../../types/common";
import Button from "../../components/Button";

const seoData: ISEOProps = {
  title: "CivicHacker Story of Qus",
  description:
    "쿠스의 시빅해킹 이야기 - 기술을 이용해 세계를 바꾸려는 시도들을 하고 있습니다",
  // imageUrl: "main/main-space-ship-light.png", TODO: 추가
};

const Container = ({ data }) => {
  const {
    allMarkdownRemark: { edges },
  } = data;

  return (
    <Header theme={"civicHacker"}>
      <SEO {...seoData} />
      <div className="content-body-civichacker">
        <div className="intro">
          <p>
            우리는 전에 없던 기술적 발전과 풍요속에서 살고 있지만
            <br />
            우리가 겪는 빈곤, 우울, 폭력 등의 고통들은 여전합니다.
          </p>
          <p>
            수천 년 간의 실패와 성공을 통해 쌓인 선배들의 경험과
            <br />
            우리의 IT기술을 결합하면
            <br />
            전에 없던 방식으로 획기적으로 그것들을 해결할 수 있지 않겠느냐는,
            <br />
            막연하고 순진한 생각을 품고 있습니다.
          </p>
          <p>
            애매한 정의감, 평범한 열정, 보통의 재능만을 가진 나지만,
            <br />
            그래도 방관자로 있고싶지는 않아 몇 가지 실험들과 시도들을 해보고
            있습니다.
          </p>
        </div>

        <div className="project-list">
          <div className="title">진행 중인 프로젝트</div>
          <div className="list">
            <div className="item">
              <div className="title">위캔두어스 WeCanDoers</div>
              <div className="desc">
                <p>
                  사회문제 현황 수집, 동일한 문제에 대한 당사자들의 연대,
                  <br />
                  활동가들의 지원이 유기적으로 지도 기반으로 유기적으로 연결
                  되도록함.
                </p>
                <p>
                  사회 문제를 소수 정치인이나 정부관계자가 아닌
                  <br />
                  그 문제에 대해 진정성과 절실함을 가진 문제 당사자,
                  <br />
                  그것을 해결할 수 있는 능력이 있고 유인이 있는 활동가 시민들이
                  <br />
                  직접 해결하는 것을 목표로 함.
                </p>
              </div>
              <Button
                label={
                  <>
                    (준비중) 자세히 보기
                    <i className="la la-external-link-alt" />
                  </>
                }
                link=""
                notYet={true}
              />
            </div>
          </div>
        </div>

        <PostListVerbose
          theme={"civicHacker"}
          title="최신글"
          postList={edges}
        />
      </div>
    </Header>
  );
};

export const query = graphql`
  query {
    allMarkdownRemark(
      limit: 3
      sort: { fields: fields___prefix, order: DESC }
      filter: { fields: { theme: { eq: "civicHacker" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            thumbNail {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
            series
            prefix(formatString: "YYYY.MM.DD")
          }
          excerpt
        }
      }
    }
  }
`;

export default Container;
